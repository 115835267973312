import PropTypes from 'prop-types';
// form
import _ from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

RHFDebouncedAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFDebouncedAutocomplete({
  name,
  label,
  helperText,
  fetchOptions = _.noop,
  getOptionLabel = _.noop,
  defaultOptions = [],
  sanitizeOptions = _.noop,
  makeRawOptions = _.noop,
  ...other
}) {
  const { control, setValue } = useFormContext();
  const [options, setOptions] = useState(sanitizeOptions([...defaultOptions]));
  const [loading, setLoading] = useState(false);
  const [usedOptions, setUsedOptions] = useState([...defaultOptions]);

  const debouncedSearch = _.debounce(async (inputValue) => {
    try {
      const {
        data: { records },
      } = await fetchOptions(inputValue);
      const sanitizedOptions = sanitizeOptions([...records, ...usedOptions]);
      setLoading(false);
      return setOptions(sanitizedOptions);
    } catch (e) {
      setLoading(false);
      setOptions([]);
    }
  }, 1500);

  const rawOptions = makeRawOptions(options);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
            setUsedOptions((prevState) => sanitizeOptions([...prevState, newValue]));
          }}
          renderInput={(params) => (
            <TextField
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          onInputChange={(_e, inputValue) => {
            setLoading(true);
            if (!_.isEmpty(inputValue) && !_.includes(options, inputValue)) {
              debouncedSearch(inputValue);
            }
          }}
          autoSelect={other.freeSolo}
          {...other}
          options={rawOptions}
          getOptionLabel={(value) => getOptionLabel(options, value)}
          noOptionsText={loading ? 'Fetching...' : 'No Options'}
        />
      )}
    />
  );
}
