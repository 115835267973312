import { useFormContext, Controller } from 'react-hook-form';
import { Stack, TextField } from '@mui/material';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import _ from 'lodash';

export default function RHFDateSelector(props) {
  const { label, name, limitToToday, maxDays = null, sx = {}, excludedDates } = props;
  const { control } = useFormContext();
  const today = moment().format('MM/DD/YYYY');
  const maxDate = _.isInteger(maxDays) && moment().add(maxDays, 'days').format('MM/DD/YYYY');
  return (
    <Stack spacing={2}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            renderInput={(opts) => (
              <TextField
                {...opts}
                fullWidth
                error={!!error}
                helperText={error && error?.message}
                sx={sx}
              />
            )}
            label={label}
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            minDate={limitToToday ? today : ''}
            maxDate={maxDate || null}
            shouldDisableDate={(d) => {
              return _.includes(excludedDates, moment(d).format('YYYY-MM-DD'))
            }}
          />
        )}
      />
    </Stack>
  );
}
