import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import announcementReducer from './slices/announcement';
import prayerRequestReducer from './slices/prayerRequest';
import testimonyReducer from './slices/testimony';
import eventReducer from './slices/event';
import dailyVerseReducer from './slices/dailyVerse';
import userReducer from './slices/user';
import participantReducer from './slices/participant';
import articleReducer from './slices/article';
import videoReducer from './slices/video';
import accountReducer from './slices/account';
import subscriptionReducer from './slices/subscription';
import dashboardReducer from './slices/dashboard';
import audioReducer from './slices/audio';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  announcement: announcementReducer,
  prayerRequest: prayerRequestReducer,
  testimony: testimonyReducer,
  event: eventReducer,
  dailyVerse: dailyVerseReducer,
  user: userReducer,
  participant: participantReducer,
  video: videoReducer,
  article: articleReducer,
  account: accountReducer,
  subscription: subscriptionReducer,
  dashboard: dashboardReducer,
  audio: audioReducer,
});

export default rootReducer;
