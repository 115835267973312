import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// _mock
import { _socials } from '../../_mock/arrays';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const nativLabsSite = 'http://nativlabs.in';

const LINKS = [
  {
    headline: 'NativLabs',
    children: [
      { name: 'About us', href: 'https://www.linkedin.com/company/nativlabs', isExternal: true },
      { name: 'FAQs', href: '#' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy },
      { name: 'Terms and Conditions', href: PATH_PAGE.termsAndConditions },
      { name: 'Cancellation & Refunds', href: PATH_PAGE.refundPolicy },
      { name: 'Delivery of Service', href: PATH_PAGE.deliveryPolicy },
    ],
  },
  {
    headline: 'Reach us',
    children: [
      { name: 'Contact us', href: PATH_PAGE.contact },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
      id="contact-us"
    >
      <Divider />

      <Container sx={{ py: 8 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} md={3}>
            <Stack item xs={12} justifyContent="center" mb={2}>
              <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={2}>
                <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                <Typography variant="caption" component="div">
                  Faith - The Church App<br/>
                  Made by &nbsp;<Link href={nativLabsSite}>NativLabs</Link> <br/> 
                  © 2023 All rights reserved
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="body2" sx={{ pr: { md: 5 } }} style={{whiteSpace: "pre-wrap"}}>
              Strengthen connections and foster a sense of community by promoting effective communication, active engagement, and nurturing the growth of members&rsquo; faith.
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
              mt={2}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <>
                      {link.isExternal ? (
                        <Link key={link.name} href={link.href} color="inherit" variant="body2" target='blank'>
                          {link.name}
                        </Link>
                      ) : (
                        <Link
                          key={link.name}
                          component={RouterLink}
                          to={link.href}
                          color="inherit"
                          variant="body2"
                        >
                          {link.name}
                        </Link>
                      )}
                    </>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  return mainFooter;
}
