import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
// utils
import nativeAxios from '../../utils/nativAxios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  mails: { byId: {}, allIds: [] },
  labels: [],

  page: 1,
  records: [],
  searchInputText: '',
  filteredRecordsCount: 0,
  searchKey: 'title',
  filterCtx: {},
};

const slice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchContentSuccess(state, action) {
      const { records = [], filtered_records_count: filteredRecordsCount } = action.payload;
      state.records = records;
      state.filteredRecordsCount = filteredRecordsCount;
      state.isLoading = false;
    },

    setFilterInputText(state, action) {
      const text = action.payload;
      state.page = 1;
      state.searchInputText = text;
    },

    setFilterCtx(state, action) {
      const filterCtx = action.payload;
      state.page = 1;
      state.filterCtx = filterCtx;
    },

    setPage(state, action) {
      const nextPage = action.payload;
      state.page = nextPage;
    },

    updateBulkEditRecords(state, action) {
      const updatedRecords = action.payload;
      const updatedRecordsByKey = _.keyBy(updatedRecords, 'id');
      const updatedRecordIds = _.keys(updatedRecordsByKey);
      const mergedRecords = _.map(state.records, (rec) => {
        return _.includes(updatedRecordIds, _.toString(rec.id))
          ? _.get(updatedRecordsByKey, rec.id, {})
          : rec;
      });
      state.records = _.chain(mergedRecords).uniqBy('id').compact().value();
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setFilterInputText, setPage, setFilterCtx, updateBulkEditRecords } = slice.actions;
// Fetchers
export function fetchContent(params = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading(params.page));
    try {
      const response = await nativeAxios.get('/announcements', {
        params,
      });
      dispatch(slice.actions.fetchContentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
