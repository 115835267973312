// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_ADMIN = '/faith/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  subscriptions: '/subscriptions',
  privacyPolicy: '/faith/privacy-policy',
  termsAndConditions: '/faith/terms-and-conditions',
  refundPolicy: '/faith/refund-policy',
  deliveryPolicy: '/faith/delivery-policy',
  payment: '/payment',
  about: '/about-us',
  contact: '/faith/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  features: '/features',
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_ADMIN, '/dashboard'),
  kanban: path(ROOTS_ADMIN, '/kanban'),
  calendar: path(ROOTS_ADMIN, '/calendar'),
  fileManager: path(ROOTS_ADMIN, '/files-manager'),

  account: path(ROOTS_ADMIN, '/account'),
  dashboard: path(ROOTS_ADMIN, '/dashboard'),
  subscriptions: path(ROOTS_ADMIN, '/subscriptions'),

  announcements: path(ROOTS_ADMIN, '/announcements'),
  editAnnouncement: (id, action) => path(ROOTS_ADMIN, `/announcements/${id}/${action}`),
  newAnnouncement: path(ROOTS_ADMIN, '/announcements/new'),

  dailyAudio: path(ROOTS_ADMIN, '/daily_audio'),
  editDailyAudio: (id, action) => path(ROOTS_ADMIN, `/daily_audio/${id}/${action}`),
  newDailyAudio: path(ROOTS_ADMIN, '/daily_audio/new'),

  prayerRequests: path(ROOTS_ADMIN, '/prayer-requests'),
  editPrayerRequest: (id, action) => path(ROOTS_ADMIN, `/prayer-requests/${id}/${action}`),
  newPrayerRequest: path(ROOTS_ADMIN, '/prayer-requests/new'),

  testimonies: path(ROOTS_ADMIN, '/testimonies'),
  editTestimony: (id, action) => path(ROOTS_ADMIN, `/testimonies/${id}/${action}`),
  newTestimony: path(ROOTS_ADMIN, '/testimonies/new'),

  events: path(ROOTS_ADMIN, '/events'),
  editEvent: (id, action) => path(ROOTS_ADMIN, `/events/${id}/${action}`),
  newEvent: path(ROOTS_ADMIN, '/events/new'),

  dailyVerse: path(ROOTS_ADMIN, '/daily-verse'),
  editDailyVerse: (id) => path(ROOTS_ADMIN, '/daily-verse/edit'),
  newDailyVerse: path(ROOTS_ADMIN, '/daily-verse/new'),

  usageGuide: path(ROOTS_ADMIN, '/usage-guide'),
  releaseNotes: path(ROOTS_ADMIN, '/release-notes'),

  users: path(ROOTS_ADMIN, '/users'),
  editUsers: (id) => path(ROOTS_ADMIN, '/users/edit'),
  newUser: path(ROOTS_ADMIN, '/users/emails'),

  participants: path(ROOTS_ADMIN, '/participants'),
  editParticipant: (id, action) => path(ROOTS_ADMIN, `/participants/${id}/${action}`),
  newParticipant: path(ROOTS_ADMIN, '/participants/new'),

  videos: path(ROOTS_ADMIN, '/videos'),
  editVideo: (id, action) => path(ROOTS_ADMIN, `/videos/${id}/${action}`),
  newVideo: path(ROOTS_ADMIN, '/videos/new'),

  articles: path(ROOTS_ADMIN, '/articles'),
  editArticle: (id, action) => path(ROOTS_ADMIN, `/articles/${id}/${action}`),
  newArticle: path(ROOTS_ADMIN, '/articles/new'),

  permissionDenied: path(ROOTS_ADMIN, '/permission-denied'),
  blank: path(ROOTS_ADMIN, '/blank'),
  general: {
    app: path(ROOTS_ADMIN, '/app'),
    ecommerce: path(ROOTS_ADMIN, '/ecommerce'),
    analytics: path(ROOTS_ADMIN, '/analytics'),
    banking: path(ROOTS_ADMIN, '/banking'),
    booking: path(ROOTS_ADMIN, '/booking'),
    file: path(ROOTS_ADMIN, '/file'),
  },
  mail: {
    root: path(ROOTS_ADMIN, '/mail'),
    all: path(ROOTS_ADMIN, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_ADMIN, '/chat'),
    new: path(ROOTS_ADMIN, '/chat/new'),
    view: (name) => path(ROOTS_ADMIN, `/chat/${name}`),
  },
  // announcements: {
  //   root: path(ROOTS_ADMIN, '/announcements'),
  //   new: path(ROOTS_ADMIN, '/user/new'),
  //   list: path(ROOTS_ADMIN, '/user/list'),
  //   cards: path(ROOTS_ADMIN, '/user/cards'),
  //   profile: path(ROOTS_ADMIN, '/user/profile'),
  //   account: path(ROOTS_ADMIN, '/user/account'),
  //   edit: path(ROOTS_ADMIN, `/user/edit`),
  //   demoEdit: path(ROOTS_ADMIN, `/user/reece-chung/edit`),
  // },
  user: {
    root: path(ROOTS_ADMIN, '/user'),
    new: path(ROOTS_ADMIN, '/user/new'),
    list: path(ROOTS_ADMIN, '/user/list'),
    cards: path(ROOTS_ADMIN, '/user/cards'),
    profile: path(ROOTS_ADMIN, '/user/profile'),
    account: path(ROOTS_ADMIN, '/user/account'),
    edit: (name) => path(ROOTS_ADMIN, `/user/${name}/edit`),
    demoEdit: path(ROOTS_ADMIN, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_ADMIN, '/e-commerce'),
    shop: path(ROOTS_ADMIN, '/e-commerce/shop'),
    list: path(ROOTS_ADMIN, '/e-commerce/list'),
    checkout: path(ROOTS_ADMIN, '/e-commerce/checkout'),
    new: path(ROOTS_ADMIN, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_ADMIN, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_ADMIN, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_ADMIN, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_ADMIN, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_ADMIN, '/invoice'),
    list: path(ROOTS_ADMIN, '/invoice/list'),
    new: path(ROOTS_ADMIN, '/invoice/new'),
    view: (id) => path(ROOTS_ADMIN, `/invoice/${id}`),
    edit: (id) => path(ROOTS_ADMIN, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_ADMIN, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_ADMIN, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_ADMIN, '/blog'),
    posts: path(ROOTS_ADMIN, '/blog/posts'),
    new: path(ROOTS_ADMIN, '/blog/new'),
    view: (title) => path(ROOTS_ADMIN, `/blog/post/${title}`),
    demoView: path(ROOTS_ADMIN, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
