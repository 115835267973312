const analyticsConfig = [
  {
    label: 'General',
    id: 'app',
    metrics: [{ id: 'app_open', label: 'App Launch' }],
  },
  {
    label: 'Announcements',
    id: 'announcements',
    metrics: [{ id: 'page_view', label: 'Page View' }],
  },
  {
    label: 'Prayer Requests',
    id: 'prayer_requests',
    metrics: [
      { id: 'page_view', label: 'Page View' },
      { id: 'user_create', label: 'User Submit' },
    ],
  },
  {
    label: 'Testimonies',
    id: 'testimonies',
    metrics: [
      { id: 'page_view', label: 'Page View' },
      { id: 'user_create', label: 'User Submit' },
    ],
  },
  {
    label: 'Events',
    id: 'events',
    metrics: [{ id: 'page_view', label: 'Page View' }],
  },
  {
    label: 'Videos',
    id: 'videos',
    metrics: [
      { id: 'page_view', label: 'Page View' },
      { id: 'view', label: 'View' },
    ],
  },
  {
    label: 'Articles',
    id: 'articles',
    metrics: [
      { id: 'page_view', label: 'Page View' },
      { id: 'view', label: 'View' },
    ],
  },
  {
    label: 'Daily Audio',
    id: 'audio',
    metrics: [{ id: 'view', label: 'Stream' }],
  },
  {
    label: 'Songs',
    id: 'songs',
    metrics: [
      { id: 'page_view', label: 'Page View' },
      { id: 'view', label: 'View' },
    ],
  },
  {
    label: 'Bible Reading Planner',
    id: 'bible_reading_plans',
    metrics: [
      { id: 'bible_reading_streak_update', label: 'Streak Update' },
      { id: 'bible_reading_plan_select', label: 'Plan Selection' },
    ],
  },
  {
    label: 'Trivia',
    id: 'trivia',
    metrics: [
      { id: 'page_view', label: 'Page View' },
      { id: 'trivia_response_submit', label: 'Response Submit' },
      { id: 'leaderboard', label: 'Leaderboard View' },
    ],
  },
  {
    label: 'About',
    id: 'about',
    metrics: [{ id: 'view', label: 'View' }],
  },
  {
    label: 'Account',
    id: 'tenant',
    metrics: [
      { id: 'app_link', label: 'Link' },
      { id: 'app_unlink', label: 'Unlink' },
    ],
  },
];

export default analyticsConfig;
