// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

const navConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.dashboard,
        icon: 'ic:round-dashboard',
      },
      {
        title: 'Users',
        path: PATH_DASHBOARD.users,
        icon: 'mdi:user',
      },
      {
        title: 'Account',
        path: PATH_DASHBOARD.account,
        icon: 'bxs:church',
      },
      {
        title: 'Subscriptions',
        path: PATH_DASHBOARD.subscriptions,
        icon: 'material-symbols:payments-outline-rounded',
      },
    ],
  },
  {
    subheader: 'content',
    items: [
      {
        title: 'Daily Verse',
        path: PATH_DASHBOARD.dailyVerse,
        icon: 'bxs:bible',
      },
      {
        title: 'Announcements',
        path: PATH_DASHBOARD.announcements,
        icon: 'mdi:bell',
      },
      {
        title: 'Prayer Requests',
        path: PATH_DASHBOARD.prayerRequests,
        icon: 'fa-solid:pray',
      },
      {
        title: 'Testimonies',
        path: PATH_DASHBOARD.testimonies,
        icon: 'material-symbols:chat',
      },
      {
        title: 'Events',
        path: PATH_DASHBOARD.events,
        icon: 'fluent:calendar-month-32-filled',
      },
      {
        title: 'Videos',
        path: PATH_DASHBOARD.videos,
        icon: 'mdi:youtube',
      },
      {
        title: 'Articles',
        path: PATH_DASHBOARD.articles,
        icon: 'material-symbols:article-rounded',
      },
      {
        title: 'Daily Audio',
        path: PATH_DASHBOARD.dailyAudio,
        icon: 'ri:mic-fill',
      },
      {
        title: 'Participants',
        path: PATH_DASHBOARD.participants,
        icon: 'heroicons:users-solid',
      },
    ],
  },
  // {
  //   subheader: 'others',
  //   items: [
  //     {
  //       title: 'Usage Guide',
  //       path: PATH_DASHBOARD.usageGuide,
  //       icon: 'icon-park-solid:guide-board',
  //     },
  //     {
  //       title: 'Release Notes',
  //       path: PATH_DASHBOARD.releaseNotes,
  //       icon: 'material-symbols:deployed-code-update',
  //     },
  //   ]
  // },
];

export default navConfig;
