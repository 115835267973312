import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));
export const AccountPage = Loadable(lazy(() => import('../pages/tabs/Account/index')));
export const DashboardPage = Loadable(lazy(() => import('../pages/tabs/Dashboard/index')));
export const SubsriptionPage = Loadable(lazy(() => import('../pages/tabs/Subscriptions/index')));

export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const FaithHome = Loadable(lazy(() => import('../pages/FaithHomePage')));
export const FeaturesPage = Loadable(lazy(() => import('../pages/Features')));
export const PricingPage = Loadable(lazy(() => import('../pages/Pricing')));
export const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
export const TermsAndConditionsPage = Loadable(lazy(() => import('../pages/TermsAndConditions')));
export const RefundPolicy = Loadable(lazy(() => import('../pages/RefundPolicy')));
export const DeliveryPolicy = Loadable(lazy(() => import('../pages/DeliveryPolicy')));
export const ContactUs = Loadable(lazy(() => import('../pages/ContactUs')));

export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

export const AnnouncementsPage = Loadable(lazy(() => import('../pages/tabs/Announcements/index')));
export const EditAnnouncementPage = Loadable(
  lazy(() => import('../pages/tabs/Announcements/Editor'))
);

export const DailyAudioPage = Loadable(lazy(() => import('../pages/tabs/DailyAudio/index')));
export const EditDailyAudioPage = Loadable(lazy(() => import('../pages/tabs/DailyAudio/Editor')));

export const PrayerRequestsPage = Loadable(
  lazy(() => import('../pages/tabs/PrayerRequests/index'))
);
export const EditPrayerRequestPage = Loadable(
  lazy(() => import('../pages/tabs/PrayerRequests/Editor'))
);
export const TestimoniesPage = Loadable(lazy(() => import('../pages/tabs/Testimonies/index')));
export const EditTestimonyPage = Loadable(lazy(() => import('../pages/tabs/Testimonies/Editor')));
export const EventsPage = Loadable(lazy(() => import('../pages/tabs/Events/index')));
export const EditEventsPage = Loadable(lazy(() => import('../pages/tabs/Events/Editor')));
export const DailyVersePage = Loadable(lazy(() => import('../pages/tabs/DailyVerse/index')));
export const UsageGuidePage = Loadable(lazy(() => import('../pages/tabs/UsageGuide/index')));
export const ReleaseNotesPage = Loadable(lazy(() => import('../pages/tabs/ReleaseNotes/index')));
export const EditDailyVersePage = Loadable(lazy(() => import('../pages/tabs/DailyVerse/Editor')));
export const UsersListPage = Loadable(lazy(() => import('../pages/tabs/Users/index')));
export const EditUsersPage = Loadable(lazy(() => import('../pages/tabs/Users/Editor')));
export const ParticipantsListPage = Loadable(
  lazy(() => import('../pages/tabs/Participants/index'))
);
export const EditParticipantsPage = Loadable(
  lazy(() => import('../pages/tabs/Participants/Editor'))
);
export const VideosListPage = Loadable(lazy(() => import('../pages/tabs/Videos/index')));
export const EditVideosPage = Loadable(lazy(() => import('../pages/tabs/Videos/Editor')));
export const ArticlesListPage = Loadable(lazy(() => import('../pages/tabs/Articles/index')));
export const EditArticlesPage = Loadable(lazy(() => import('../pages/tabs/Articles/Editor')));
