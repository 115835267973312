import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------
const clientId = '265789789823-7sa5aa0huttnaprfsd7nu5aps2af7m7e.apps.googleusercontent.com';

export default function AuthLoginForm() {
  const { login } = useAuthContext();
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const loginSuccessCb = () =>{
    navigate(PATH_DASHBOARD.dashboard);
  }

  const onGApiSuccess = async ({ tokenId: googleTokenId }) => {
    setLoading(true)
    try {
      await login(googleTokenId, loginSuccessCb);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onGApiFail = () => {
    setLoading(false);
  };

  return (
    <Stack>
      <Stack display="none">
        <GoogleLogin
          clientId={clientId}
          onSuccess={onGApiSuccess}
          onFailure={onGApiFail}
          cookiePolicy="single_host_origin"
          buttonText="Continue With Google"
          className="google-login-btn"
        />
      </Stack>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        onClick={() => {
          const el = document.querySelectorAll('.google-login-btn');
          el[0].click();
        }}
        variant="contained"
        loading={isLoading}
        sx={{
          bgcolor: 'text.primary',
          color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          '&:hover': {
            bgcolor: 'text.primary',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          },
        }}
      >
        Login
      </LoadingButton>
    </Stack>
  );
}
