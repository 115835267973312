import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import nativeAxios from '../../utils/nativAxios';

const initialState = {
  isLoading: false,
  error: null,
  page: 1,
  records: [],
  searchInputText: '',
  filteredRecordsCount: 0,
  searchKey: 'title',
  filterCtx: {},
};

const slice = createSlice({
  name: 'dailyVerse',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchContentSuccess(state, action) {
      const { records = [], filtered_records_count: filteredRecordsCount } = action.payload;
      state.records = records;
      state.filteredRecordsCount = filteredRecordsCount;
      state.isLoading = false;
    },

    setFilterInputText(state, action) {
      const text = action.payload;
      state.page = 1;
      state.searchInputText = text;
    },

    setPage(state, action) {
      const nextPage = action.payload;
      state.page = nextPage;
    },

    setFilterCtx(state, action) {
      const filterCtx = action.payload;
      state.page = 1;
      state.filterCtx = filterCtx;
    },

    updateDailyVerse(state, action) {
      const newVerse = action.payload;
      const { book, verse, text, date, chapter } = newVerse;
      const updatedRecords = _.map(state.records, (rec) => {
        return rec.date === newVerse.date
          ? {
              book,
              verse,
              text,
              date,
              chapter,
            }
          : rec;
      });
      state.records = updatedRecords;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setFilterInputText, setPage, setFilterCtx, updateDailyVerse } = slice.actions;
// Fetchers
export function fetchContent(params = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading(params.page));
    try {
      const response = await nativeAxios.get('/daily_verses', {
        params,
      });
      dispatch(slice.actions.fetchContentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
