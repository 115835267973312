import { useFormContext, Controller } from 'react-hook-form';
import { Stack, TextField } from '@mui/material';
import {
  TimePicker,
  MobileTimePicker,
  StaticTimePicker,
  DesktopTimePicker,
  DateTimePicker,
  DatePicker,
} from '@mui/x-date-pickers';

export default function RHFTimeSelector(props) {
  const { label, name } = props;
  const { control } = useFormContext();

  return (
    <Stack spacing={2}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TimePicker
            renderInput={(opts) => <TextField {...opts} fullWidth />}
            label={label}
            value={field.value}
            // views={["hours","minutes"]}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            ampmInClock
            // inputFormat="h:m a"
          />
        )}
      />
    </Stack>
  );
}

