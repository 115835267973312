// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// config
import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'Our Products',
    icon: <Iconify icon="eos-icons:product-subscriptions" />,
    path: '/faith',
  },
  // {
  //   title: 'Know More',
  //   icon: <Iconify icon="bi:linkedin" />,
  //   path: '/faith',
  // },
  {
    title: 'Contact Us',
    icon: <Iconify icon="ic:baseline-mail" />,
    path: '/faith/contact-us',
  },
  // {
  //   title: 'Privacy Policy',
  //   icon: <Iconify icon="ic:baseline-privacy-tip" />,
  //   path: '/faith/privacy-policy',
  // },
  // {
  //   title: 'Terms and Conditions',
  //   icon: <Iconify icon="ic:baseline-privacy-tip" />,
  //   path: '/faith/terms-and-conditions',
  // },
];

export default navConfig;
