import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import CompactLayout from '../layouts/compact';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  // Dashboard: General
  GeneralAppPage,
  AccountPage,
  AnnouncementsPage,
  EditAnnouncementPage,
  DailyAudioPage,
  EditDailyAudioPage,
  PrayerRequestsPage,
  EditPrayerRequestPage,
  TestimoniesPage,
  EditTestimonyPage,
  EventsPage,
  EditEventsPage,

  //
  BlankPage,

  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FeaturesPage,
  PricingPage,
  MaintenancePage,
  DailyVersePage,
  UsersListPage,
  EditUsersPage,
  EditParticipantsPage,
  ParticipantsListPage,
  VideosListPage,
  EditVideosPage,
  ArticlesListPage,
  EditArticlesPage,
  DashboardPage,
  SubsriptionPage,
  PrivacyPolicyPage,
  TermsAndConditionsPage,

  UsageGuidePage,
  ReleaseNotesPage,
  RefundPolicy,
  DeliveryPolicy,
  ContactUs,
  FaithHome,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
      ],
    },

    // Dashboard
    {
      path: '/faith/admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
        { path: '', element: <DashboardPage />},
        { path: 'blank', element: <BlankPage /> },
        { path: 'account', element: <AccountPage /> },

        { path: 'announcements', element: <AnnouncementsPage /> },
        { path: 'announcements/:id/:action', element: <EditAnnouncementPage /> },
        { path: 'announcements/new', element: <EditAnnouncementPage /> },

        { path: 'daily_audio', element: <DailyAudioPage /> },
        { path: 'daily_audio/:id/:action', element: <EditDailyAudioPage /> },
        { path: 'daily_audio/new', element: <EditDailyAudioPage /> },

        { path: 'prayer-requests', element: <PrayerRequestsPage /> },
        { path: 'prayer-requests/:id/:action', element: <EditPrayerRequestPage /> },
        { path: 'prayer-requests/new', element: <EditPrayerRequestPage /> },

        { path: 'testimonies', element: <TestimoniesPage /> },
        { path: 'testimonies/:id/:action', element: <EditTestimonyPage /> },
        { path: 'testimonies/new', element: <EditTestimonyPage /> },

        { path: 'events', element: <EventsPage /> },
        { path: 'events/:id/:action', element: <EditEventsPage /> },
        { path: 'events/new', element: <EditEventsPage /> },

        { path: 'daily-verse', element: <DailyVersePage /> },
        // { path: 'usage-guide', element: <UsageGuidePage /> },
        // { path: 'release-notes', element: <ReleaseNotesPage /> },

        { path: 'users', element: <UsersListPage /> },
        { path: 'users/emails', element: <EditUsersPage /> },

        { path: 'participants', element: <ParticipantsListPage /> },
        { path: 'participants/:id/:action', element: <EditParticipantsPage /> },
        { path: 'participants/new', element: <EditParticipantsPage /> },

        { path: 'videos', element: <VideosListPage /> },
        { path: 'videos/:id/:action', element: <EditVideosPage /> },
        { path: 'videos/new', element: <EditVideosPage /> },

        { path: 'articles', element: <ArticlesListPage /> },
        { path: 'articles/:id/:action', element: <EditArticlesPage /> },
        { path: 'articles/new', element: <EditArticlesPage /> },

        { path: 'dashboard', element: <DashboardPage />},
        { path: 'subscriptions', element: <SubsriptionPage /> },
      ],
    },
    {
      element:  <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
      ],
    },
    {
      path: '/faith',
      element:  <MainLayout />,
      children: [
        { element: <FaithHome />, index: true },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: 'terms-and-conditions', element: <TermsAndConditionsPage /> },
        { path: 'refund-policy', element: <RefundPolicy /> },
        { path: 'delivery-policy', element: <DeliveryPolicy /> },
        { path: 'contact-us', element: <ContactUs /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
