import axios from 'axios';

const baseURL = 'https://www.nativlabs.in/api/v1/admin';
// const baseURL = 'http://localhost:3000/api/v1/admin';

const nativeAxios = axios.create({ baseURL });

nativeAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const {status = null} = error?.response || {};
    if (status === 401 || status === 403) {
      localStorage.removeItem('accessToken');
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default nativeAxios;
