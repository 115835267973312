import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import _ from 'lodash';
import axios from '../utils/axios';
import nativeAxios from '../utils/nativAxios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  tenant: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    const { username, avatar, tenant, isAuthenticated } = action.payload;
    return {
      isInitialized: true,
      isAuthenticated,
      user: { username, avatar },
      tenant,
    };
  }
  if (action.type === 'LOGIN') {
    const { username, avatar, tenant } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user: { username, avatar },
      tenant,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...initialState,
      isAuthenticated: false,
      user: null,
      tenant: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const validateUserDetails = ({ username, avatar, tenant = {} }) => {
  return !_.isEmpty(username) && !_.isEmpty(avatar) && !_.isEmpty(tenant);
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initializeFailed = () => {
    dispatch({
      type: 'INITIAL',
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : null;
      setSession(accessToken);

      if (_.isEmpty(accessToken)) {
        return initializeFailed();
      }

      const response = await nativeAxios.post('/me');
      const isValid = validateUserDetails(response.data);
      if (isValid) {
        const { token: newAccessToken, username, avatar, tenant } = response.data;
        setSession(newAccessToken);
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            username,
            avatar,
            tenant,
          },
        });
      } else {
        initializeFailed();
      }
    } catch (error) {
      console.error(error);
      initializeFailed();
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (googleTokenId, successCb) => {
    const response = await nativeAxios.post('/login', { id_token: googleTokenId });
    const isValid = validateUserDetails(response.data);
    
    if (isValid) {
      const { token: accessToken, username, avatar, tenant } = response.data;
      setSession(accessToken);
  
      dispatch({
        type: 'LOGIN',
        payload: {
          username,
          avatar,
          tenant
        },
      });
      successCb()
    } else {
      initializeFailed();
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      tenant: state.tenant,
      method: 'jwt',
      login,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, state.tenant, login, logout]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
