import { createSlice } from '@reduxjs/toolkit';
// utils
import nativeAxios from '../../utils/nativAxios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  privateEmails: [],
  androidUsers: null,
  iosUsers: null,
  totalUsers: null,


  page: 1,
  records: [],
  searchInputText: '',
  filteredRecordsCount: 0,
  searchKey: 'username',
  filterCtx: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchContentSuccess(state, action) {
      const { android, ios } = action.payload;
      state.androidUsers = android;
      state.iosUsers = ios;
      state.totalUsers = android + ios;
      state.isLoading = false;
    },

    setPrivateEmails(state, action) {
      state.privateEmails = action.payload.privateEmails;
    },

    setFilterInputText(state, action) {
      const text = action.payload;
      state.page = 1;
      state.searchInputText = text;
    },

    setPage(state, action) {
      const nextPage = action.payload;
      state.page = nextPage;
    },

    setFilterCtx(state, action) {
      const filterCtx = action.payload;
      state.page = 1;
      state.filterCtx = filterCtx;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setFilterInputText, setPage, setFilterCtx, setPrivateEmails } = slice.actions;
// Fetchers

export function fetchPrivateEmails(params = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await nativeAxios.get('/private_emails');
      const { private_emails: privateEmails } = response.data;
      dispatch(slice.actions.setPrivateEmails({ privateEmails }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

  export function uploadPrivateEmails(emails = [], uploadCb) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await nativeAxios.post('/reset_private_emails', {emails});
      const { private_emails: privateEmails } = response.data;
      dispatch(slice.actions.setPrivateEmails({ privateEmails }));
      uploadCb();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      uploadCb();
    }
  };
}

export function fetchContent(params = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await nativeAxios.get('/active_users_count', {
        params,
      });
      dispatch(slice.actions.fetchContentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
