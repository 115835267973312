import { createSlice } from '@reduxjs/toolkit';
import _, { transform } from 'lodash';
import moment from 'moment';
// utils
import nativeAxios from '../../utils/nativAxios';
import analyticsConfig from '../../pages/tabs/Dashboard/AnalyticsConfig';
// ----------------------------------------------------------------------

// [start_date, ...between, end_date]
const getDatesBetween = (startDate, endDate, presentDate) => {
  // eslint-disable-next-line prefer-const
  let dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.format('YYYY-MM-DD'));
  }

  return _.uniq([startDate, ...dates, endDate, presentDate]);
};

const sanitizeAggValues = ({ aggValues, datesBetween }) =>
  _.reduce(
    datesBetween,
    (acc, date) => {
      const aggValueForDate = _.find(aggValues, { date }) || {};

      return { ...acc, [date]: _.isEmpty(aggValueForDate) ? { date } : aggValueForDate };
    },
    {}
  );

const transformData = (data) => {
  const {
    records = [],
    start_date: startDate,
    end_date: endDate,
    present_date: presentDate,
  } = data;
  const datesBetween = getDatesBetween(startDate, endDate, presentDate);
  const transformedData = _.reduce(
    records,
    (acc, aggValues, action) => {
      const sanitizedAggValues = sanitizeAggValues({ aggValues, datesBetween });
      const mainAggValue = _.find(sanitizedAggValues, (val, key) => key === presentDate) || {};
      const comparatorAggValues = _.pickBy(sanitizedAggValues, (val, key) => key !== presentDate);
      return { ...acc, [action]: { mainAggValue, comparatorAggValues } };
    },
    {}
  );
  return transformedData;
};

const initialTab = _.head(analyticsConfig);
const initialState = {
  isLoading: false,
  error: null,
  currentTab: initialTab,
  currentMetric: _.head(initialTab.metrics),
  allTabConfig: analyticsConfig,

  analyticsData: {
    startDate: null,
    endDate: null,
    presentDate: null,
    isExtended: true,
  },

  page: 1,
  records: [],
  searchInputText: '',
  filteredRecordsCount: 0,
  searchKey: 'title',
  filterCtx: {},
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchContentSuccess(state, action) {
      const { data, subject } = action.payload;
      const {
        start_date: startDate,
        end_date: endDate,
        present_date: presentDate,
        is_extended: isExtended,
      } = data;
      const transformedData = transformData(data);
      const updatedAnalyticsData = {
        startDate,
        endDate,
        presentDate,
        [subject]: transformedData,
      };
      state.analyticsData = _.merge(state.analyticsData, updatedAnalyticsData);
      state.isExtended = isExtended;
      state.isLoading = false;
    },

    setCurrentTab(state, action) {
      const tab = action.payload;
      state.currentTab = tab;
      state.currentMetric = _.head(tab.metrics);
    },

    setCurrentMetric(state, action) {
      const metric = action.payload;
      state.currentMetric = metric;
    },

    setFilterInputText(state, action) {
      const text = action.payload;
      state.page = 1;
      state.searchInputText = text;
    },

    setFilterCtx(state, action) {
      const filterCtx = action.payload;
      state.page = 1;
      state.filterCtx = filterCtx;
    },

    setPage(state, action) {
      const nextPage = action.payload;
      state.page = nextPage;
    },

    updateBulkEditRecords(state, action) {
      const updatedRecords = action.payload;
      const updatedRecordsByKey = _.keyBy(updatedRecords, 'id');
      const updatedRecordIds = _.keys(updatedRecordsByKey);
      const mergedRecords = _.map(state.records, (rec) => {
        return _.includes(updatedRecordIds, _.toString(rec.id))
          ? _.get(updatedRecordsByKey, rec.id, {})
          : rec;
      });
      state.records = _.chain(mergedRecords).uniqBy('id').compact().value();
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  setFilterInputText,
  setPage,
  setFilterCtx,
  updateBulkEditRecords,
  setCurrentTab,
  setCurrentMetric,
} = slice.actions;
// Fetchers
export function fetchContent(params = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await nativeAxios.get('/analytic_event_aggregates', {
        params,
      });
      const payload = { data: response.data, subject: params.subject };
      dispatch(slice.actions.fetchContentSuccess(payload));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
